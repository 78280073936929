/* global SB_UrlDir */
import { SB_Timestamp, SB_Microtime } from "./common_functions.es6";
import { SB_Input } from "./ui_elements.es6";
import { Class, Ajax, Prototype } from "Prototype";
import { SB_Popup } from "./popup.es6";
import { SB_handleErrorResponse } from "./message_stack.es6";

import axios from 'axios';

class myCancelToken {
	/**
	 * @param {{ (c: any): void; (arg0: (message: any) => void): void; }} executor
	 */
	constructor(executor) {
		if (typeof executor !== 'function') {
			throw new TypeError('executor must be a function.');
		}
		var resolvePromise;
		this.promise = new Promise(resolve => {
			resolvePromise = resolve;
		});
		executor(message => {
			if (this.reason) {
				// Cancellation has already been requested
				return;
			}
			this.reason = (message instanceof Error ? message : new Error(message));
			resolvePromise(this.reason);
		});
	}

    /**
     * Throws a `Cancel` if cancellation has been requested.
     */
	throwIfRequested() {
		if (this.reason) {
			throw this.reason;
		}
	}

    /**
     * Returns an object that contains a new `myCancelToken` and a function that, when called,
     * cancels the `myCancelToken`.
	 * 
	 * @returns {{token: myCancelToken, cancel: function}}
     */
	static source() {
		var cancel;
		var token = new myCancelToken(function executor(c) {
			cancel = c;
		});
		return {
			token: token,
			cancel: cancel
		};
	}
}


var cancel_tokens = {};
axios.interceptors.request.use(function (config) {
	if (config.autoCancel) {
		if (cancel_tokens[config.autoCancel]) {
			cancel_tokens[config.autoCancel].cancel(`Request was cancelled by newer request on same queue: '${config.autoCancel}'`);
		}
		cancel_tokens[config.autoCancel] = myCancelToken.source();
		config.cancelToken = cancel_tokens[config.autoCancel].token;
	}
	return config;
})

function resetQueue(queueName) {
	if (cancel_tokens[queueName]) {
		cancel_tokens[queueName].cancel(`Request was cancelled by newer request on same queue: '${queueName}'`);
	}
	cancel_tokens[queueName] = myCancelToken.source();

	return cancel_tokens[queueName];
}

export { /* myCancelToken,  */resetQueue };
export { axios };


var SB_AjaxRequests = {};
var SB_AjaxGlobalLatestRequest = false;
var SB_AjaxGlobalLatestLoadingRequest = false;
var SB_AjaxGlobalLoadingBox = false;
var SB_AjaxGlobalLoadingBackground = false;
var SB_AjaxGlobalLoadingBackgroundEffect = false;
export class SB_Ajax {
	
	constructor(Url, Params) {
		this.Options 			= false,
		this.Delay				= false,
		this.RequestMicrotime	= false,
		
		this.Url 				= false,
		this.External 			= false,
		this.Params 			= false,
		
		this.Target 			= false,
		this.TargetId 			= false,
		this.TargetInput		= false,
		
		this.LoadingEffect 		= "global",
		this.LoadingBox 		= false,
		
		this.CustomEvents		= false,
		this.ignoreDefaultFailure = false;
		this.AjaxLoaderGif 		= "design2/ajax-loader.gif",
		
		this.Url = Url;

		Params = Params || {};
		if (typeof (Params) == "object") {
			this.Params = Params;
			this.Params.t = SB_Timestamp();
		} else {
			this.Params = (Params) ? Params + "&t=" + SB_Timestamp() : "t=" + SB_Timestamp();
		}

		this.Options = {
			method: 'post',
			parameters: this.Params,
			onCreate: this.onCreate.bind(this),
			onSuccess: this.onSuccess.bind(this),
			onFailure: this.onFailure.bind(this),
			onComplete: this.onComplete.bind(this),
			requestHeaders: {},
		}

		// Her sættes en header med location og en samling af alle åbne popups.
		// Det kan nemlig bruges i Log_entry.class, så man let kan se _hvorfra_
		// en ændring er foretaget. Det vil gøre det meget lettere for os at
		// spore hvis noget er gået galt, og hjælpe folk med hvad det er de skal
		// gøre/undgå fremover.
		var Path = SB_Popup.getCurrentPath().join(" / ");
		if (Path) {
			this.Options.requestHeaders['X-Popup-Path'] = window.location.pathname.replace(/^\//, "") + " / " + Path;
		} else {
			this.Options.requestHeaders["X-Popup-Path"] = window.location.pathname.replace(/^\//, "");
		}

		this.CustomEvents = {};
		this.Delay = 500;

		return this;
	}

	SetMethod(method = 'post') {
		if (['get', 'post', 'put', 'delete'].include(method)) {
			this.Options.method = method;
		}
		return this;
	}

	/**
	 * Markerer at svaret kommer fra en utroværdig kilde, og scripts ikke skal evalueres.
	 * @param {boolean} bool 
	 * @returns this
	 */
	SetExternal(bool = true) {
		this.External = !!bool;

		return this;
	}

	SetTarget(element, LoadingEffect)
	{
		this.LoadingEffect = (typeof(LoadingEffect)=="undefined") ? "target" : LoadingEffect;
			
		this.Target = $(element);
		if (this.Target)
		{
			this.TargetId = this.Target.identify();
			this.TargetInput = (this.Target.tagName.toLowerCase() == 'input' || this.Target.tagName.toLowerCase() == 'textarea');
		}
		
		return this;
	}

	SetTargetId(id) // kun til situationer hvor der ikke er noget target men man skal bruge delay alligevel
	{
		this.TargetId = id;

		return this;
	}
	
	SetLoadingEffect(LoadingEffect)
	{
		this.LoadingEffect = LoadingEffect;
		return this;
	}

	SetAjaxLoaderGif(url)
	{
		this.AjaxLoaderGif = url;
		return this;
	}
	
	SetDelay(Delay)
	{
		this.Delay = Delay;
		
		return this;
	}

	AddEvent(name, fnc, action = "append")
	{	
		this.CustomEvents[name] = fnc;
		
		return this;
	}
	
	Request()
	{
		this.RequestMicrotime = SB_Microtime();
		SB_AjaxGlobalLatestRequest = this.RequestMicrotime;
		
		if (this.TargetId)
		{
			if (typeof(SB_AjaxRequests[this.TargetId]) == "undefined")
				SB_AjaxRequests[this.TargetId] = {
					timeout : false,
					microtime : this.RequestMicrotime,
					transport : false
				};
			else
				SB_AjaxRequests[this.TargetId].microtime = this.RequestMicrotime;
		}
		
		this.StartLoadingEffect();
		
		if (typeof this.Options.parameters == "object") {
			this.Options.parameters = serialize(this.Options.parameters);
		}

		if (this.TargetId && this.Delay)
		{
			if (SB_AjaxRequests[this.TargetId].timeout!==false)
			{
				clearTimeout(SB_AjaxRequests[this.TargetId].timeout);
				SB_AjaxRequests[this.TargetId].timeout = false;
			}
			resetQueue(this.TargetId);
				
			this.promise = new Promise((resolve, reject) => {
				SB_AjaxRequests[this.TargetId].timeout = setTimeout(() => {
					SB_AjaxRequests[this.TargetId].timeout = false;
					if (SB_AjaxRequests[this.TargetId] && SB_AjaxRequests[this.TargetId].microtime == this.RequestMicrotime) {
						this.resolve = resolve;
						this.reject = reject;
						axios({
							method: this.Options.method,
							baseURL: SB_UrlDir,
							url: this.Url,
							data: this.Options.parameters,
							headers: {'content-type': 'application/x-www-form-urlencoded'},
							autoCancel: this.TargetId,
						})
						.then(response => {
							// response.responseText = String(response.data);
							response.responseText = typeof response.data == "object" ? JSON.stringify(response.data) : String(response.data);
							response.responseJSON = typeof response.data == "object" ? response.data : {};
							this.Options.onSuccess(response);
							return response;
						}, err => {
							this.Options.onFailure(err);
							// throw err;
						})
						.then(response => {
							this.Options.onComplete()
							return response;
						}, err => {
							this.Options.onComplete()
							// throw err;
						})
						// new Ajax.Request(SB_UrlDir + this.Url, this.Options);
					}
				}, this.Delay);
			});
		}
		else {
			this.promise = new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
				axios({
					method: this.Options.method,
					baseURL: SB_UrlDir,
					url: this.Url,
					data: this.Options.parameters,
					headers: {'content-type': 'application/x-www-form-urlencoded'},
				})
				.then(response => {
					response.responseText = typeof response.data == "object" ? JSON.stringify(response.data) : String(response.data);
					response.responseJSON = typeof response.data == "object" ? response.data : {};
					this.Options.onSuccess(response);
					return response;
				}, error => {
					this.Options.onFailure(error);
					// throw error;
				})
				.then(response => {
					this.Options.onComplete()
					return response;
				}, error => {
					this.Options.onComplete()
					// throw error;
				})
				// new Ajax.Request(SB_UrlDir + this.Url, this.Options);
			})
		}
		
		return this.promise;
	}
	
	onCreate(response)
	{
		// if (this.TargetId)
		// {
		// 	if (SB_AjaxRequests[this.TargetId].transport!==false && SB_AjaxRequests[this.TargetId].transport.readyState < 4)
		// 		SB_AjaxRequests[this.TargetId].transport.onreadystatechange = function () {};
			
		// 	SB_AjaxRequests[this.TargetId].transport = response.transport;
		// }
		
		if (typeof(this.CustomEvents.onCreate) != "undefined")
			this.CustomEvents.onCreate();
	}
	
	onSuccess(response)
	{
/*		if (response.getHeader("Content-Type") == "application/pdf")
		{
			var pdfwindow = window.open('data:application/pdf,' + escape(response.responseText));
		}
*/
		if (this.TargetId && (typeof SB_AjaxRequests[this.TargetId] === "undefined" || SB_AjaxRequests[this.TargetId].microtime != this.RequestMicrotime))
			return false;
		
		if (this.Target && $(this.Target))
		{
			if (this.TargetInput)	SB_Input(this.Target).update(response.responseText.stripScripts());
			else					this.Target.update(response.responseText.stripScripts());
		}
		
		if (!this.External) {
			response.responseText.evalScripts();
		}

		this.StopLoadingEffect();
	

		if (typeof(this.CustomEvents.onSuccess) != "undefined")
			this.CustomEvents.onSuccess(response);

		if (typeof(this.promise) != "undefined")
			this.resolve(response);

	}
	
	onComplete(response)
	{
		if (this.TargetId && (typeof SB_AjaxRequests[this.TargetId] === "undefined" || SB_AjaxRequests[this.TargetId].microtime != this.RequestMicrotime))
			return false;
			
		if (this.TargetId)
			delete(SB_AjaxRequests[this.TargetId]);
		
		if (typeof(this.CustomEvents.onComplete) != "undefined")
			this.CustomEvents.onComplete();
	}
	
	setIgnoreDefaultFailure(bool = true) {
		this.ignoreDefaultFailure = !!bool;
		return this;
	}

	onFailure(error)
	{
		let response = error.response;
		if (response.data && response.data.response && SB_handleErrorResponse(response.data.response)) {
			delete response.data.response.success;
			delete response.data.response.notice;
			delete response.data.response.error;
		} else if (!this.ignoreDefaultFailure) {
			// Vi laver ignorerer fejl ved page unload, fordi firefox kan finde på at cancel en ajaxrequest når man
			// klikker hen på en ny side. Det gør at man lige når at se en fejlmeddelelse, og i nogle tilfælde hvis
			// siden er delvist unloaded, så får man en alert i et splitsekund. Det giver et irriterende "flash"
			if (error.message.match("cancelled by newer request") || error.message == "Request aborted") { // Annulleret af canceltoken eller page unload
				
			} else {
				if (this.Target && !this.TargetInput) {
					if (typeof SB_AjaxRequests[this.TargetId] !== "undefined" && SB_AjaxRequests[this.TargetId].microtime == this.RequestMicrotime) {
						this.Target.update("Der opstod en fejl! \n\nDet ser ud til, at du mistede forbindelsen til SkvizBiz-serveren. Prøv venligst igen, eller kontakt SkvizBiz-teamet, hvis problemet gentager sig");
					}
				} else {
					alert("Der opstod en fejl! \n\nDet ser ud til, at du mistede forbindelsen til SkvizBiz-serveren. Prøv venligst igen, eller kontakt SkvizBiz-teamet, hvis problemet gentager sig");
				}
			}
		}

		this.StopLoadingEffect();
		if (typeof(this.CustomEvents.onFailure) != "undefined")
			this.CustomEvents.onFailure();

		if (typeof(this.promise) != "undefined") {
			this.resolve(response);
			// this.reject(response);
		}
	}
	
	StartLoadingEffect()
	{
		if ((!this.Target || !$(this.Target)) && (this.LoadingEffect == "default"))
			this.LoadingEffect = false;
		
		if (this.LoadingEffect !== false) {
			$(document.body).addClassName('wait');
		}
		
		if (this.LoadingEffect == "target" || this.LoadingEffect == "target_noblur")
		{
			$(document.body).addClassName('wait');
			if (this.LoadingEffect == "target" ) {
				this.Target.addClassName("target-loading").setOpacity(0.3);
			}
	
			if (!this.TargetInput && !this.Target.down(".AjaxLoadingBox"))
			{
				var h = this.Target.getHeight();
				
				var ImgMarginTop = (h > 0) ? Math.min( 100, (h-32) / 2) : 0;
				this.LoadingBox = new Element('div').setStyle({display : 'none'}).addClassName('AjaxLoadingBox').update(get_ajax_loader()/*'<img src="'+this.AjaxLoaderGif+'" width="32" height="32" style="margin-top: '+ImgMarginTop+'px;" />'*/);
				
				if (h > 0)
					this.LoadingBox.setStyle({position : 'absolute'});
				
				this.Target.insert({'top' : this.LoadingBox});
				this.LoadingBox.clonePosition(this.Target, {setHeight: true}).show();
			}
	
		}
	// Hvis der ikke er et target, vises loading i toppen
		else if (this.LoadingEffect == "global")
		{
//			$(document.body).addClassName('wait');
			
			SB_AjaxGlobalLatestLoadingRequest = this.RequestMicrotime;
			
			if (SB_AjaxGlobalLoadingBox === false)
			{
				SB_AjaxGlobalLoadingBox = new Element('div').setStyle({display : 'block'}).addClassName('AjaxLoadingBox Global').update(get_ajax_loader()/*'<img src="'+this.AjaxLoaderGif+'" width="32" height="32" style="" />'*/);
				SB_AjaxGlobalLoadingBackground = new Element('div').addClassName('AjaxLoadingBackground Global').setStyle({'opacity' : 0, transition: "opacity 1s linear"});
			
				$(document.body).insert({'bottom' : SB_AjaxGlobalLoadingBackground});
				$(document.body).insert({'bottom' : SB_AjaxGlobalLoadingBox});
				
				if (SB_AjaxGlobalLoadingBackgroundEffect!==false)
					clearTimeout(SB_AjaxGlobalLoadingBackgroundEffect);
				
				SB_AjaxGlobalLoadingBackgroundEffect = setTimeout(function() {
					getComputedStyle(SB_AjaxGlobalLoadingBackground).transform;
					SB_AjaxGlobalLoadingBackground.setStyle({opacity: 1});
					SB_AjaxGlobalLoadingBackgroundEffect = false;
				}, 5000);
			}
		}
	}
	
	StopLoadingEffect()
	{
		if ((!this.Target || !$(this.Target)) && (this.LoadingEffect == "default"))
			this.LoadingEffect = false;
		
		if (this.RequestMicrotime == SB_AjaxGlobalLatestRequest)
			$(document.body).removeClassName('wait');
			
		
		if (this.LoadingEffect == "target")
		{
//			if (this.LoadingBox)
//				this.LoadingBox.remove();
			if (this.TargetId && (typeof SB_AjaxRequests[this.TargetId] === "undefined" || SB_AjaxRequests[this.TargetId].microtime != this.RequestMicrotime))
				return false;
			setTimeout((function() {
					
				this.Target.setOpacity(1);
				setTimeout((function(){
					this.Target.removeClassName("target-loading");
				}).bind(this), 100);
			}).bind(this),1);
		}
		
//		else if (this.LoadingEffect == "global")*/
//		{
			setTimeout((function() {
				if (this.RequestMicrotime == SB_AjaxGlobalLatestLoadingRequest && SB_AjaxGlobalLoadingBackground !== false)
				{
					if (SB_AjaxGlobalLoadingBackgroundEffect!==false)
					{
						clearTimeout(SB_AjaxGlobalLoadingBackgroundEffect);
						SB_AjaxGlobalLoadingBackgroundEffect = false;
					}
					
					if (SB_AjaxGlobalLoadingBackground.getOpacity() != 0)
					{
						getComputedStyle(SB_AjaxGlobalLoadingBackground).transform;
						SB_AjaxGlobalLoadingBackground.addEventListener("transitionend", function tmpRemove() {
							if (SB_AjaxGlobalLoadingBackground) {
								SB_AjaxGlobalLoadingBackground.removeEventListener("transitionend", tmpRemove);
								if (SB_AjaxGlobalLoadingBackground.getOpacity() == 0) {
									SB_AjaxGlobalLoadingBackground.remove()
									SB_AjaxGlobalLoadingBackground = false;
									SB_AjaxGlobalLoadingBackgroundEffect = false;
								}
							}
						});
						SB_AjaxGlobalLoadingBackground.setStyle({opacity : 0, transition: "opacity .3s linear"});
					}
					else
					{
						SB_AjaxGlobalLoadingBackground.remove();
						SB_AjaxGlobalLoadingBackground = false;
					}
					
					SB_AjaxGlobalLoadingBox.remove();
					SB_AjaxGlobalLoadingBox = false;
				}
//				this.Target.setOpacity(1);
			}).bind(this),2);
			
//		}
	}
}

export function get_ajax_loader() {
	return '<svg class="loading-squeegee" width="100%" height="100%" viewBox="0 0 180 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;"><path d="M7.979,6.86l164.047,0c1.095,0 1.977,0.882 1.977,1.977l1,6.011c0,1.108 -0.892,2 -2,2l-58.875,0c-2.276,1.988 -6.04,5.346 -7.878,7.382c-2.112,2.339 -4.982,6.424 -6.184,8.17l0.428,0c-0.005,0.008 -2.056,3.902 -2.5,5.541c-0.445,1.642 -0.5,5.542 -0.5,5.542l1,47.511c0,2.77 -2.23,5 -5,5l-6.969,0c-2.77,0 -5,-2.23 -5,-5l1,-47.511c0,0 -0.056,-3.9 -0.5,-5.542c-0.444,-1.639 -2.496,-5.533 -2.5,-5.541l0.409,0c-1.202,-1.746 -4.072,-5.831 -6.184,-8.17c-1.838,-2.036 -5.602,-5.394 -7.878,-7.382l-58.869,0c-1.108,0 -2,-0.892 -2,-2l1,-6.011c0,-1.096 0.882,-1.977 1.976,-1.977c0,0 -1.094,0 0,0Z" style="fill:none;stroke:currentColor;"/></svg>';
}

// Et "hack" som gør at der ikke laves fejl i fejlloggen ved Cross-domain-ajax-opslag.
// Brug Ajax.CorsRequest helt almindeligt i stedet for Ajax.Request når det er en adresse uden for domænet, f.eks. https://api.dataforsyningen.dk
Ajax.CorsResponse = Class.create(Ajax.Response, {
	_getHeaderJSON: Prototype.emptyFunction
});
Ajax.CorsRequest = Class.create(Ajax.Request, {
  request: function(url) {
    this.url = url;
    this.method = this.options.method;
    var params = Object.isString(this.options.parameters) ?
          this.options.parameters :
          Object.toQueryString(this.options.parameters);

    if (!['get', 'post'].include(this.method)) {
      params += (params ? '&' : '') + "_method=" + this.method;
      this.method = 'post';
    }

    if (params && this.method === 'get') {
      this.url += (this.url.include('?') ? '&' : '?') + params;
    }

    this.parameters = params.toQueryParams();

    try {
      var response = new Ajax.CorsResponse(this);
      if (this.options.onCreate) this.options.onCreate(response);
      				var t = response.transport;
					t.setRequestHeader = t.setRequestHeader.wrap(function(original, k, v) {
						if (/^(accept|accept-language|content-language)$/i.test(k))
							return original(k, v);
						if (/^content-type$/i.test(k) &&
							/^(application\/x-www-form-urlencoded|multipart\/form-data|text\/plain)(;.+)?$/i.test(v))
							return original(k, v);
						return;
					});
      Ajax.Responders.dispatch('onCreate', this, response);

      this.transport.open(this.method.toUpperCase(), this.url,
        this.options.asynchronous);

      if (this.options.asynchronous) this.respondToReadyState.bind(this).defer(1);

      this.transport.onreadystatechange = this.onStateChange.bind(this);
      this.setRequestHeaders();

      this.body = this.method == 'post' ? (this.options.postBody || params) : null;
      this.transport.send(this.body);

      /* Force Firefox to handle ready state 4 for synchronous requests */
      if (!this.options.asynchronous && this.transport.overrideMimeType)
        this.onStateChange();

    }
    catch (e) {
      this.dispatchException(e);
    }
  },

  respondToReadyState: function(readyState) {
    var state = Ajax.Request.Events[readyState], response = new Ajax.CorsResponse(this);

    if (state == 'Complete') {
      try {
        this._complete = true;
        (this.options['on' + response.status]
         || this.options['on' + (this.success() ? 'Success' : 'Failure')]
         || Prototype.emptyFunction)(response, response.headerJSON);
      } catch (e) {
        this.dispatchException(e);
      }

      var contentType = response.getHeader('Content-type');
      if (this.options.evalJS == 'force'
          || (this.options.evalJS && this.isSameOrigin() && contentType
          && contentType.match(/^\s*(text|application)\/(x-)?(java|ecma)script(;.*)?\s*$/i)))
        this.evalResponse();
    }

    try {
      (this.options['on' + state] || Prototype.emptyFunction)(response, response.headerJSON);
      Ajax.Responders.dispatch('on' + state, this, response, response.headerJSON);
    } catch (e) {
      this.dispatchException(e);
    }

    if (state == 'Complete') {
      this.transport.onreadystatechange = Prototype.emptyFunction;
    }
  },
});


var AjaxPollCache = {
	responses: {},
	origTimeouts: {},
	timeouts: {},
	started: {},
};
export function AjaxPoll(url, data, timeout, callback) {
	var cacheKey = url + serialize(data);
	AjaxPollCache.responses[cacheKey] = "";
	AjaxPollCache.origTimeouts[cacheKey] = timeout;
	AjaxPollCache.timeouts[cacheKey] = timeout;
	AjaxPollCache.started[cacheKey] = ~~(Date.now()/1000);
	data.skip_timeout_cookie = true;
	callback = callback || function(){};
	var a = new SB_Ajax(url, data).SetLoadingEffect(false).AddEvent('onSuccess', function(transport){
		if(AjaxPollCache.responses[cacheKey] != transport.responseText) {
			AjaxPollCache.responses[cacheKey] = transport.responseText;
			callback(transport.responseText);
			AjaxPollCache.timeouts[cacheKey] = AjaxPollCache.origTimeouts[cacheKey];
			setTimeout(function(){a.Request()}, AjaxPollCache.timeouts[cacheKey]);
		} else {
			AjaxPollCache.timeouts[cacheKey] = Math.min(AjaxPollCache.timeouts[cacheKey] * 2, AjaxPollCache.origTimeouts[cacheKey] * 8);
			if (AjaxPollCache.started[cacheKey] + 20*60 > ~~(Date.now()/1000)) {
				setTimeout(function(){a.Request()}, AjaxPollCache.timeouts[cacheKey]);
			}
		}
	});
	a.Request();
	// setTimeout(function(){a.Request()}, AjaxPollCache.timeouts[url]);
}

export var serialize = function(obj, prefix) {
  var str = [], p;
  for(p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + (typeof v === "boolean" ? (v?1:0) : encodeURIComponent(v)));
    }
  }
  return str.join("&").replace( /%20/g, "+" );
}

export function cached_get(url, callback) {
	if (sessionStorage.getItem(`get_cache_${url}`)) {
		try {
			callback(JSON.parse(sessionStorage.getItem(`get_cache_${url}`)));
		} catch (err) {}
	}
	axios.get(url).then((response) => {
		callback(response);
		sessionStorage.setItem(`get_cache_${url}`, JSON.stringify(response));
	})
}