import {SB_Onload} from "./sb_modules/common_functions.es6.js";

export { createPopper } from '@popperjs/core';
import Tooltip from 'tippy.js';
Tooltip.defaultProps.duration = 0;
Tooltip.defaultProps.appendTo = (elem) => elem.closest("body, .Popup");
export {Tooltip};
import 'tippy.js/dist/tippy.css';
// import 'tippy.js/dist/svg-arrow.css';
// import {roundArrow} from 'tippy.js';

function checkHoveredElement(el = null) {
	if (el !== null && Array.prototype.indexOf.call(document.body.querySelectorAll(":hover"), el) != -1) {
		return el;
	}

	let tmp_el = document.body;
	let tmp_el2 = document.body;
	while ((tmp_el2 = tmp_el.querySelector(":hover"))) {
		tmp_el = tmp_el2;
		if (tmp_el === el) {
			break;
		}
	}
	return tmp_el;
}

SB_Onload(function(){
	jQuery(document).on("mouseenter", '[data-toggle="tooltip"]', function(/* evt */) {
		let content = this.getAttribute("data-original-title");
		let interactive = this.getAttribute("data-interactive");
		if (!content) {
			return;
		}
		let el = this;

		let placement = el.getAttribute("data-placement") || 'bottom';
		let theme = el.getAttribute("data-theme") || "";
		let target_element = el;
		let target_element_selector = el.getAttribute("data-tooltip-element");
		if (target_element_selector) {
			let target_element_selectors = target_element_selector.split(" | ");
			if (target_element_selectors.length == 2) {
				target_element = el.closest(target_element_selectors[0]).querySelector(target_element_selectors[1]);
			} else {
				target_element = el.closest(target_element_selector);
			}
		}
		if (!target_element) {
			return;
		}
		// var container = el.closest("body, .Popup");
		try {
			var T = new Tooltip(target_element, {
				placement: placement,
				allowHTML: true,
				content: content,
				theme: theme,
				maxWidth: 'none',
				// interactive: false,
				interactive: interactive ? true : false,
				// appendTo: interactive ? document.body : (() => document.body),
				// appendTo: (elem) => elem.closest("body, .Popup"),
				// arrow: roundArrow,
				// boundariesElement: 'viewport',
				popperOptions: {
					// strategy: interactive ? "fixed" : "absolute",
					modifiers: [
						{
							name: "flip",
							// enabled: false,
							options: {
								boundary: "viewport",
								rootBoundary: 'viewport',
								padding: 0,
							}
						},
						{
							name: "arrow",
							options: {
								padding: ({ popper, reference, placement }) => {
									// console.log(placement);
									if (placement == "bottom-start") {
										return {right: popper.width - (16 + 2), left: -20};
									} else if (placement == "bottom-end") {
										return {left: popper.width - (16 + 2), right: -20};
									}
									return 2;
								},
							}
						},
						// {
						// 	name: "preventOverflow",
						// 	options: {
						// 		// boundary: document.body,
						// 		// rootBoundary: 'viewport',
						// 		// altAxis: true,
						// 		// tether: false,
						// 		// priority: ['right','left','bottom','top'],
						// 	},
						// },
						
					],
				},
			})
			T.show();
		} catch (err) {
			// console.log(err);
			return;
		}

		Array.prototype.forEach.call(T.popper.querySelectorAll("img"), (el)=>{
			el.addEventListener("load", ()=>{T.popperInstance.update()}, {once: true});
			// el.onload = ()=>{T.popperInstance.update()}
		});
		requestAnimationFrame(()=>{
			T.popperInstance.update();
		});

		var interval = setInterval(() => {
			if (this != checkHoveredElement(this)) {
				if (interactive && T.popper == checkHoveredElement(T.popper)) {
					return;
				}
				if (T.state.isDestroyed === false) {
					T.destroy();
				}
				T = null;
				clearInterval(interval);
			}
		}, 200);
	}).on("mouseleave", '[data-toggle="tooltip"]', function(/* evt */) {
		let interactive = this.getAttribute("data-interactive");
		if (interactive) {
			return;
		}

		if (this._tippy) {
			this._tippy.destroy();
		}
		let target_element = this;
		let target_element_selector = this.getAttribute("data-tooltip-element");
		if (target_element_selector) {
			let target_element_selectors = target_element_selector.split(" | ");
			if (target_element_selectors.length == 2) {
				target_element = this.closest(target_element_selectors[0]).querySelector(target_element_selectors[1]);
			} else {
				target_element = this.closest(target_element_selector);
			}
		}
		if (target_element._tippy) {
			target_element._tippy.destroy();
		}


		// console.log(el);
		// requestAnimationFrame(()=>{
		// 	requestAnimationFrame(()=>{
		// 		var T = el.data("tooltip");
		// 		if (T) {
		// 			T.destroy();
		// 			el.removeData('tooltip');
		// 		}
		// 	})
		// });
	})
})